import React, { useContext, useReducer, createContext, useEffect } from 'react';

import { useApplication } from 'hooks/application';
import useAxios from 'hooks/axios';

import urls from 'shared/urls';
import { mapData, mapCircuits } from './mapper';

const CyTContext = createContext();

const useCyT = () => {
    const [results, setResults] = useContext(CyTContext);

    const setTrip = trip => setResults({ type: 'trip', value: trip });
    const setCircuits = circuits => setResults({ type: 'circuits', value: circuits });

    return { ...results, setTrip, setResults, setCircuits };
};

const getCircuits = (destinationId, destinationName) => {
    const context = useCyT();
    const axios = useAxios();
    const { setCircuits } = context;
    const { destinations, trip, setTrip } = getIndex(`ApiCircuitosTraslados/${destinationId ? 'Index' : 'IndexTransfers'}`);

    useEffect(() => {
        if (destinations.length) {
            const destination = destinations.find(destination => urls.toSEOFriendly(destination.name) === urls.toSEOFriendly(destinationName));

            const getCircuits = async destination => {
                const res = await axios.get(`ApiCircuitosTraslados/BuscarCircuitos?idDestino=${destination.id}`);
                setCircuits(mapCircuits(res.data));
            };

            getCircuits(destination);
            setTrip({ ...trip, destination });
        }
    }, [destinations, destinationId, destinationName]);

    return context;
}

const getIndex = endpoint => {
    const context = useCyT();
    const axios = useAxios();
    const { culture, country, idPv } = useApplication();
    const { setResults } = context;

    useEffect(() => {
        const get = async () => {
            const res = await axios.get(`${endpoint}?idPv=${idPv}&lang=${culture.iso_code}&pais=${country.url}`);
            if(res && res.data){
                const result = mapData(res.data);
                setResults({ type: 'result', value: result })
            }
        };

        get();
    }, []);

    return context;
};

const CyTProvider = ({ children, value }) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'result':
                return { ...state, ...action.value }
            default:
                return { ...state, [action.type]: action.value }
        }
    }

    const context = value || {
        trip: {},
        destinations: [],
        circuits: [],
        subtitle: '',
        countries: []
    };

    return (
        <CyTContext.Provider value={useReducer(reducer, context)}>
            {children}
        </CyTContext.Provider>
    )
}

export { CyTProvider, useCyT, getIndex, getCircuits }
import urls from 'shared/urls';

const mapDestinations = destinations => {
    return destinations.map(destination => {
        const { Agrupador, Id, IdPais, Nombre, Pais, Provincia } = destination;

        return {
            id: Id,
            groupBy: Agrupador,
            name: Nombre, 
            countryId: IdPais,
            country: Pais,
            city: Provincia
        }
    });
}

const mapCountries = countries => {
    return countries.map(countrie => {
        const { Id, Nombre } = countrie;

        return {
            id: Id,
            name: Nombre
        }
    });
}

const mapData = data => {
    const { Subtitulo, Metadata, DestinosViewModel:{ Destinos, Paises } } = data;

    return {
        subtitle: Subtitulo,
        metadata: Metadata,
        destinations: mapDestinations(Destinos),
        countries: mapCountries(Paises)
    }
}

const mapOriginAndDestination = destinations => {
    return destinations.map(destination => {
        const { IdPad, NombrePad } = destination;

        return {
            padId: IdPad,
            padName: NombrePad
        }
    });
}

const mapAditionalImages = images => {
    return images.map((image, index) => {
        return {
            image:{
                id: index,
                image: urls.getCloudFrontPath(`circuitos/${image}`),
                alt: 'slide',
                title: 'slide'
            },
        }
    });
}

const mapCircuits = data => {
    return data.map(circuit => {
        const { 
            Descripcion, DescripcionNormalizada, Destinos, Imagen, Nombre, Origenes, 
            PrecioBase, Id, Dias, EsDestino, EsFechaFija, Fechas, ImagenesAdicionales
        } = circuit;
        
        return {
            id:Id,
            name: Nombre,
            description: Descripcion,
            standardizedDescription: DescripcionNormalizada,
            destinations: mapOriginAndDestination(Destinos),
            days: Dias,
            isDestination: EsDestino,
            isFixedDate: EsFechaFija,
            dates: Fechas,
            image: Imagen,
            aditionalImages: mapAditionalImages(ImagenesAdicionales),
            departures: mapOriginAndDestination(Origenes),
            basePrice: PrecioBase
        }
    });
}

export {
    mapData,
    mapCircuits
}